import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Header } from 'Header/Header';
import { MessageBox } from 'MessageBox/MessageBox';
import { PayModal } from 'components/UI/PayModal/PayModal';
import { PopupBox } from 'PopupBox/PopupBox';
import { ReportModal } from 'pages/Projects/ReportModal/ReportModal';
import { Reset } from 'pages/Reset/Reset';
import { Restore } from 'pages/Restore/Restore';
import { SignIn } from 'pages/SignIn/SignIn';
import { SignUp } from 'pages/SignUp/SignUp';
import { SureModal } from 'components/UI/SureModal/SureModal';
import { StopProjectModal } from 'components/UI/SureModal/StopProjectModal';
import { WarrantyPopup } from 'components/WarrantyPopup/WarrantyPopup';
import { AcceptRulesModal } from 'components/AcceptRulesModal/AcceptRulesModal';
import { RenderFAQModal } from 'pages/Projects/ReportModal/FAQReportModal/RenderFAQModal/RenderFAQModal';
import AppWithFooter from 'AppWithFooter';
import WarningBox from 'WarningBox/WarningBox';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PaymentForm from 'pages/PaymentForm/PaymentForm';
import FullScreenshotModal from 'pages/Projects/components/FullScreenshotModal/FullScreenshotModal';
import BotModal from 'components/BotModal/BotModal';

import AppStore from 'AppStore';
import messageBoxStore from 'MessageBox/MessageBoxStore';
import popupStore from 'components/Popup/PopupStore';

import './App.scss';

export const App = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#success')
      messageBoxStore.showInfo('Вы успешно пополнили баланс!');
    else if (location.hash === '#failed')
      messageBoxStore.showError('Ошибка пополнения баланса.', 'Попробуйте еще раз.');
  }, [location]);

  useEffect(() => {
    if (AppStore.isAuth)
      AppStore.updateProfileAndMaybeShowWarning();
  }, [navigate]);

  useEffect(() => {
    if (AppStore.isDropdownLinksOpen || popupStore.isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppStore.isDropdownLinksOpen, popupStore.isOpen]);

  return (
    <>
      <Header />
      <Breadcrumbs />
      <Routes>
        <Route path='/signin' element={<SignIn />} />
        <Route path='/signup' element={<SignUp />} />
        <Route path='/restore' element={<Restore />} />
        <Route path='/reset' element={<Reset />} />
        {AppStore.isAuth && (
          <Route path='/payment-form' element={<PaymentForm />} />
        )}

        <Route path='/*' element={<AppWithFooter />} />
      </Routes>

      <SureModal />
      <StopProjectModal />
      <PayModal />
      <ReportModal />
      <AcceptRulesModal />

      <MessageBox />
      <WarningBox />
      <PopupBox />
      <WarrantyPopup store={AppStore.currentMarketStore} />

      <RenderFAQModal />
      <FullScreenshotModal />
      <BotModal />
    </>
  );
});
