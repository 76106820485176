/* eslint-disable react/no-is-mounted */

import { makeAutoObservable, runInAction } from "mobx";
import { distance } from "fastest-levenshtein";
import validator from "validator";

import { Project } from "models/project";

import client, { ApiError, ReportStatuses } from "client";
import { getCookie } from "utils/cookie";

import messageBoxStore from "MessageBox/MessageBoxStore";
import popupStore from "components/Popup/PopupStore";

import MarketStore from "pages/markets/MarketStore";

import Avito from 'models/markets/Avito';
import Megamarket from 'models/markets/Megamarket';
import Ozon from 'models/markets/Ozon';
import Telegram from 'models/markets/Telegram';
import Vk from 'models/markets/Vk';
import Wildberries from 'models/markets/Wildberries';
import YandexMaps from 'models/markets/YandexMaps';
import YandexMarket from 'models/markets/YandexMarket';
import YandexSeo from "models/markets/YandexSeo";

const WARNING_MESSAGE = [
  "Необходимо проверить отчет исполнителя.",
  <br />,
  <br />,
  "Если ответы на контрольные вопросы верны, то примите задание. " +
    "Исполнитель получит оплату за свою работу.",
  <br />,
  <br />,
  "Если ответы отсутствуют или неверные, то отклоните или отправьте на доработку.",
];

export const MARKETS = [
  Avito,
  YandexMaps,
  YandexSeo,
  Telegram,
  Vk,
  Ozon,
  Wildberries,
  YandexMarket,
  Megamarket,
];

// TODO: use singletons instead strings
export const MARKET_GROUPS = {
  'Маркетплейсы и торговля': [
    'avito',
    'ozon',
    'wildberries',
    'yandexmarket',
    'megamarket',
  ],
  'Мессенджеры и социальные сети': [
    'telegram',
    'vk',
  ],
  //'Видеохостинги и стримминги': [],
  'SEO (поисковики)': [
    'yandexseo',
  ],
  'Агрегаторы и карты': [
    'yandexmaps',
  ],
  //'Эксклюзивно в Boostclick': []
}

const MAX_SCREENSHOT_SIZE_PX = 1024;

class AppStoreClass {
  constructor() {
    makeAutoObservable(this);

    this.savedHidePaymentNotification();
  }

  isMarketPage(pathname) {
    return !!this.findMarketStoreAndCalculatorByPath(pathname);
  }

  token = getCookie("jwt");

  setToken(token) {
    this.token = token;
    if (token) document.cookie = `jwt=${token};path=/;max-age=31536000`;
    else document.cookie = "jwt=;path=/;expires=-1";
  }

  get isAuth() {
    return !!this.token;
  }

  isFormModeLogin = true;
  setIsFormModeLogin(mode) {
    this.isFormModeLogin = mode;
  }

  warningVisible = false;
  setWarningVisible(visible) {
    this.warningVisible = visible;
  }

  profile;
  // TODO: use profile member
  userEmail = undefined;
  telegramNotify = undefined;
  telegramId = undefined;

  updatingProfile = false;
  async updateProfile() {
    if (this.updatingProfile) return false;

    this.updatingProfile = true;
    try {
      this.profile = await client.getProfile();
      runInAction(() => {
        // TODO: use this.profile
        this.userEmail = this.profile.login;
        this.telegramNotify = this.profile.telegramNotify;
        this.telegramId = this.profile.telegramId ?? null;
      });
      return true;
    } finally {
      this.updatingProfile = false;
    }
  }

  async updateProfileAndMaybeShowWarning() {
    this.setWarningVisible(
      (await this.updateProfile()) && this.profile.warning
    );
  }

  OTP = null;
  receivingCode = false;
  async turnOnNotifications() {
    if (this.receivingCode) return false;

    this.receivingCode = true;
    try {
      const response = await client.issueCode();
      runInAction(() => {
        this.OTP = response.text;
      });
      return true;
    } catch (error) {
      console.error("Ошибка при изменении статуса уведомлений:", error);
      throw error;
    } finally {
      this.receivingCode = false;
    }
  }

  async onOffTelegramNotifications(notify = true) {
    try {
      const response = await client.onOffTelegramNotifications(notify);
      await this.updateProfile();
      return response.message;
    } catch (error) {
      console.error("Ошибка при изменении статуса уведомлений:", error);
      throw error;
    }
  }

  agreedRules = false;
  agreedPrivacy = false;
  agreedWithRules = false;

  setAgreedRules(value) {
    this.agreedRules = value;
  }

  setAgreedPrivacy(value) {
    this.agreedPrivacy = value;
  }

  setAgreedWithRules(value) {
    this.agreedWithRules = value;
  }

  resetAgreed() {
    this.agreedRules = false;
    this.agreedPrivacy = false;
  }

  acceptAgreementsUser = false;
  async updateAcceptAgreements() {
    if (this.acceptAgreementsUser) return false;

    this.acceptAgreementsUser = true;
    try {
      await client.acceptAgreements();
      this.updateProfile();
      return true;
    } finally {
      this.acceptAgreementsUser = false;
    }
  }

  async handleAgreeClickForLoggedInUser() {
    if (this.isAuth) {
      await this.updateAcceptAgreements();
    }
  }

  hidePaymentNotification = false;
  setHidePaymentNotification(value) {
    this.hidePaymentNotification = value;
    localStorage.setItem('hidePaymentNotification', value);
  }

  savedHidePaymentNotification() {
    const savedHidePaymentNotification = localStorage.getItem('hidePaymentNotification');
    if (savedHidePaymentNotification !== null) {
      this.hidePaymentNotification = JSON.parse(savedHidePaymentNotification);
    }
  }

  marketStores = MARKETS.map((market) => new MarketStore(market));
  currentMarketStoreIndex = (() => {
    const path = document.location.pathname;
    const marketStoreIndex = this.marketStores.findIndex((marketStore) =>
      path.includes(marketStore.market.id)
    );
    if (marketStoreIndex >= 0) return marketStoreIndex;
    return 0;
  })();
  currentMarketStore = this.marketStores[this.currentMarketStoreIndex];

  findMarketStoreById(id) {
    return this.marketStores.find(
      (marketStore) => marketStore.market.id === id
    );
  }

  findMarketStoreAndCalculatorByPath(path) {
    while (path.endsWith("/")) path = path.substring(0, path.length - 1);

    if (!path) path = "/";

    for (let i = 0; i < this.marketStores.length; ++i) {
      for (let j = 0; j < this.marketStores[i].calculators.length; ++j) {
        if (this.marketStores[i].calculators[j].mode.path === path)
          return { i: i, j: j };
      }
    }
    return null;
  }

  selectMarketStoreByIndex(index) {
    this.currentMarketStoreIndex = index;
    this.currentMarketStore = this.marketStores[index];
  }

  selectMarketStoreAndCalculatorByIndexes(i, j) {
    this.selectMarketStoreByIndex(i);
    this.currentMarketStore.selectCalculator(j);
  }

  selectMarketStoreAndCalculatorByPath(path) {
    const ij = this.findMarketStoreAndCalculatorByPath(path);
    if (!ij) return false;
    this.selectMarketStoreAndCalculatorByIndexes(ij.i, ij.j);
    return true;
  }

  marketsMap = MARKETS.reduce((map, market) => {
    map[market.id] = market;
    return map;
  }, {});

  projects = null;

  removeProjectLocally(project) {
    this.projects = this.projects.filter((p) => p !== project);
  }

  isUpdatingProjects = false;
  isDeletingProject = false;
  isPayingProject = false;
  isFinishingProject = false;
  isRepeatingProject = false;
  isCancellingProject = false;

  async updateProjects({ shouldClearCurrent = false } = {}) {
    if (this.isUpdatingProjects) return;

    if (shouldClearCurrent) this.projects = null;

    this.isUpdatingProjects = true;
    try {
      const projects = (await client.getProjects()).reverse();
      runInAction(() => {
        this.projects = projects.map((project) =>
          makeAutoObservable(new Project(project))
        );
      });
    } finally {
      runInAction(() => {
        this.isUpdatingProjects = false;
      });
    }
  }

  deleteProjectWithConfirm(project) {
    popupStore.setText("Вы действительно хотите удалить проект?");
    popupStore.setOk(() => {
      this.deleteProject(project);
    });
    popupStore.open("suremodal");
  }

  async deleteProject(project) {
    if (this.isDeletingProject) return;

    this.isDeletingProject = true;
    try {
      await client.deleteProject(project.id);
      this.removeProjectLocally(project);
      await this.updateProjects();
    } finally {
      runInAction(() => {
        this.isDeletingProject = false;
      });
    }
  }

  async payProject(project) {
    if (this.isPayingProject) return;

    this.isPayingProject = true;
    try {
      if (!(await client.payProject(project.id)))
        return messageBoxStore.showError(
          "Недостаточно средств!",
          null,
          "Пополнить",
          () => {
            popupStore.open("paymodal");
          }
        );

      if (!this.hidePaymentNotification) {
        messageBoxStore.showInfo(
          "Проект успешно оплачен и находится в разделе Активные проекты",
          null,
          "Ок",
          null,
          true
        );
        this.setActiveTab("active");
      }
      this.removeProjectLocally(project);
      await this.updateProjects();
    } finally {
      runInAction(() => {
        this.isPayingProject = false;
      });
    }
  }

  async stopProjectWithConfirm(project) {
    popupStore.setOk(async () => {
      try {
        await this.cancelProject(project);
      } catch (e) {
        if (e instanceof ApiError) {
          messageBoxStore.showError(e.message);
          return;
        }
        throw e;
      }
    });
    popupStore.open("stopmodal");
  }

  async archiveProjectWithConfirm(project) {
    popupStore.setText("Вы действительно хотите архивировать проект?");
    popupStore.setOk(() => {
      this.finishProject(project);
    });
    popupStore.open("suremodal");
  }

  async finishProject(project) {
    if (this.isFinishingProject) return;

    this.isFinishingProject = true;
    try {
      await client.finishProject(project.id);
      this.removeProjectLocally(project);
      await this.updateProjects();
    } finally {
      runInAction(() => {
        this.isFinishingProject = false;
      });
    }
  }

  async cancelProject(project) {
    if (this.isCancellingProject) return;

    this.isCancellingProject = true;
    try {
      await client.cancelProject(project.id);
      this.removeProjectLocally(project);
      await this.updateProjects();
    } finally {
      runInAction(() => {
        this.isCancellingProject = false;
      });
    }
  }

  async repeatProject(project) {
    if (this.isRepeatingProject) return;

    this.isRepeatingProject = true;
    try {
      await client.copyProject(project.id);
      await this.updateProjects();
      messageBoxStore.showInfo(
        "Повтор проекта успешно выполнен и находится в разделе «Черновики»"
      );
      this.setActiveTab("drafts");
    } finally {
      runInAction(() => {
        this.isRepeatingProject = false;
      });
    }
  }

  setReportStatus(projectId, reportId, status) {
    this.projects
      .find((project) => project.id === projectId)
      .reports.find((report) => report.id === reportId).status = status;
  }

  setReportAsReplaced(projectId, reportId) {
    this.setReportStatus(projectId, reportId, ReportStatuses.REPLACE);
  }
  setReportsAsApproved(projectId, reportId) {
    this.setReportStatus(projectId, reportId, ReportStatuses.APPROVED);
  }
  setReportAsRejected(projectId, reportId) {
    this.setReportStatus(projectId, reportId, ReportStatuses.REWORK);
  }

  // настройки проекта:
  isSetupOpen = false;
  setIsSetupOpen(value) {
    this.isSetupOpen = value;
  }

  openSetupProjectId = null;
  setOpenSetupProjectId(project) {
    this.openSetupProjectId = project;
  }

  editingTitle = false;
  setEditingTitle(value) {
    this.editingTitle = value;
  }

  newTitle = "";
  setNewTitle(value) {
    this.newTitle = value;
  }

  editingProjectId = null;
  isEditingProjectTitle = false;
  startEditingProjectTitle(projectId) {
    this.isEditingProjectTitle = true;
    this.editingProjectId = projectId;
  }

  stopEditingProjectTitle() {
    this.isEditingProjectTitle = false;
    this.editingProjectId = null;
  }

  isValidProjectTitle(currentTitle, newTitle) {
    if (newTitle === null || newTitle === undefined) return false;
    const trimmedNewTitle = newTitle.trim();
    if (!trimmedNewTitle) return false;
    if (currentTitle === trimmedNewTitle) return false;

    return true;
  }

  newLink = "";
  setNewLink(value) {
    this.newLink = value;
  }

  isEditingNewLink = false;
  startEditingNewLink(value) {
    this.isEditingNewLink = value;
  }

  projectScreenshot = null;
  async loadProjectScreenshot(project) {
    if (!project) return;

    try {
      const screenshot = await client.getProjectScreenshot(project.id);
      runInAction(() => {
        this.projectScreenshot = screenshot;
      });
    } catch (error) {
      console.error("Ошибка при загрузке скриншота проекта:", error);
    }
  }

  isUpdatingProjectDetails = false;
  async updateProjectDetails(project, { title, link, screenshot }) {
    if (this.isUpdatingProjectDetails) return;
  
    const updateData = {};
    let isValid = true;
  
    if (title !== undefined) {
      if (this.isValidProjectTitle(project.title, title)) {
        updateData.title = title;
      } else {
        isValid = false;
        messageBoxStore.showError("Наименование проекта не заполнено или не отличается от текущего");
      }
    }
  
    if (link !== undefined && link !== project.link) {
      if (validator.isURL(link)) {
        updateData.link = link;
      } else {
        isValid = false;
        messageBoxStore.showError("Недопустимая ссылка");
      }
    }
  
    if (screenshot !== undefined && screenshot !== this.projectScreenshot) {
      updateData.screenshot = screenshot;
    }
  
    if (!isValid || Object.keys(updateData).length === 0) return;
  
    this.isUpdatingProjectDetails = true;
    try {
      await client.updateProject(project.id, updateData);
  
      runInAction(() => {
        if (updateData.title) project.title = updateData.title;
        if (updateData.link) project.link = updateData.link;
        if (updateData.screenshot) project.screenshot = updateData.screenshot;
      });
  
      messageBoxStore.showInfo("Проект успешно обновлен");
      return true;
    } catch (error) {
      messageBoxStore.showError("Ошибка при обновлении проекта. Повторите попытку");
      console.error("Ошибка при обновлении проекта:", error);
      return false;
    } finally {
      runInAction(() => {
        this.isUpdatingProjectDetails = false;
      });
    }
  }

  uploadedFiles = {};
  uploadedFileError = false;
  setUploadedFile(fileType = "default", file) {
    this.uploadedFiles[fileType] = file;
    this.uploadedFileError = false;
    if (fileType === 'default') {
      this.uploadedFile = file;
      this.uploadedFileError = false;
    }
  }

  getUploadedFile(fileType = "default") {
    return this.uploadedFiles[fileType];
  }

  clearUploadedFile(fileType) {
    this.uploadedFiles[fileType] = null;
    this.uploadedFileError = false;
    if (fileType === 'default') {
      this.uploadedFile = null;
      this.uploadedFileError = false;
    }
  }

  computeScreenshotBlob = (file) => {
    return new Promise((res, rej) => {
      const fileReader = new FileReader();
      fileReader.onerror = () => {
        rej(new Error(fileReader.error.message));
      };
      fileReader.onloadend = () => {
        const img = new Image();
        img.onerror = () => {
          rej(new Error('Ошибка преобразования скриншота'));
        };
        img.onload = () => {
          const canvas = document.createElement("canvas");

          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > MAX_SCREENSHOT_SIZE_PX) {
              height *= MAX_SCREENSHOT_SIZE_PX / width;
              width = MAX_SCREENSHOT_SIZE_PX;
            }
          } else {
            if (height > MAX_SCREENSHOT_SIZE_PX) {
              width *= MAX_SCREENSHOT_SIZE_PX / height;
              height = MAX_SCREENSHOT_SIZE_PX;
            }
          }
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const canvasReader = new FileReader();
            canvasReader.onerror = () => {
              rej(new Error(canvasReader.error.message));
            };
            canvasReader.onloadend = () => {
              const base64data = canvasReader.result.split(',')[1];
              res(base64data);
            };
            canvasReader.readAsDataURL(blob);
          }, "image/jpeg", 1);
        };
        img.src = fileReader.result;
      };
      fileReader.readAsDataURL(file);
    }).catch(() => {
      messageBoxStore.showError('Не удалось обработать скриншот объявления. Пожалуйста, повторите попытку');
      return null;
    });
  }

  showReadyReportsWarning = false;
  setShowReadyReportsWarning(value) {
    this.showReadyReportsWarning = value;
  }

  lastWarningTime = null;
  checkReadyReportsAndShowWarning() {
    const now = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    if (this.lastWarningTime === null || now - this.lastWarningTime >= oneDay) {
      const readyReports = this.projects?.flatMap((project) =>
        project.reports.filter((report) => report.status === "ready")
      );

      if (readyReports && readyReports.length > 0) {
        this.setShowReadyReportsWarning(true);
        messageBoxStore.showWarning(
          WARNING_MESSAGE,
          null,
          "Перейти к проверке"
        );
        this.lastWarningTime = now;
      } else {
        this.setShowReadyReportsWarning(false);
      }
    }
  }

  isDropdownLinksOpen = false;
  setIsDropdownLinksOpen(value) {
    this.isDropdownLinksOpen = value;
  }

  searchQuery = "";
  setSearchQuery(query) {
    this.searchQuery = query;
  }

  clearSearchQuery() {
    this.searchQuery = "";
  }

  levenshteinSearch(keyword, query, maxDistance = 1) {
    const lowercaseKeyword = keyword.toLowerCase().trim();
    const lowercaseQuery = query.toLowerCase().trim();

    if (lowercaseKeyword === lowercaseQuery) return true;
    if (lowercaseKeyword.includes(lowercaseQuery)) return true;

    return distance(lowercaseKeyword, lowercaseQuery) <= maxDistance;
  }

  get filteredMarkets() {
    if (!this.searchQuery) return this.marketStores;

    const trimmedQuery = this.searchQuery.trim();
    return this.marketStores.filter(marketStore =>
      marketStore.market?.keywords.some(keyword =>
        this.levenshteinSearch(keyword, trimmedQuery)
      )
    );
  }

  activeTab = "active";
  setActiveTab(tab) {
    this.activeTab = tab; 
  }
  
  // filter projects by cities 
  selectedCities = {
    active: {},
    archive: {}
  };
  setSelectedCity(section, type, city) {
    this.selectedCities[section][type] = city;
  }

  getSelectedCity(section, type) {
    return this.selectedCities[section][type] || "Все города";
  }

  getCitiesForType(typeProjects) {
    const citySet = new Set(typeProjects.map(project => project.city || "Нет города"));
    const sortedCities = Array.from(citySet)
      .filter(city => city !== "Нет города")
      .sort((a, b) => a.localeCompare(b, "ru"));
    return ["Все города", "Нет города", ...sortedCities];
  }

  getFilteredProjects(section, type, typeProjects) {
    const selectedCity = this.getSelectedCity(section, type);
    if (selectedCity === "Все города") return typeProjects;
    return typeProjects.filter(project => (project.city || "Нет города") === selectedCity);
  }
}

const AppStore = new AppStoreClass();
export default AppStore;
